'use strict';

var ajax = require('../../ajax'),
    formPrepare = require('./formPrepare'),
    giftcard = require('../../giftcard'),
    dialog = require('../../dialog'),
    progress = require('../../progress'),
    util = require('../../util');

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type);
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('[name$="creditCard_selectedCardID"]').val(data.selectedCardID).trigger('change');
    if (!('CHECKOUT_AGENT' in SessionAttributes && SessionAttributes.CHECKOUT_AGENT == true)) {
    		$creditCard.find('input[name$="_cvn"]').val('').trigger('change');
    }
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
                window.alert(Resources.CC_LOAD_ERROR);
                return false;
            }
            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
    var $paymentMethods = $('.payment-method');
    $paymentMethods.removeClass('payment-method-expanded');

    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    $selectedPaymentMethod.addClass('payment-method-expanded');

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

    formPrepare.validateForm();
}

    // Validate the return quantity input value with actual product quantity.
    $(document).ready(function () {
        $('.input_returntext').on('input', function () {
            var returnQuantity = parseInt($(this).val());
            var actualQuantity = parseInt($(this).closest('tr').find('td:eq(3)').text());

            // Check if the input is a valid number and within the range
            if (isNaN(returnQuantity) && returnQuantity >= 1 && returnQuantity <= actualQuantity) {
                $(this).val();
            } else if (returnQuantity > actualQuantity || returnQuantity === 0) {
                $(this).val(actualQuantity);
            }
        });
    });

        // Validate the each row in the table whether checkbox checked or not
        function validatereturnqty() {
                var isValid = true;
                // Iterate through each row in the table
                $('#tableqty tbody tr').each(function () {
                    var checkbox = $(this).find('input[name="dwfrm_orders_selectforReturn"]');
                    var returnQuantityInput = $(this).find('.input_returntext');

                    if (!checkbox.prop('checked')) {
                        returnQuantityInput.val('');
                    }
                    // Check if the checkbox is selected and return quantity input is empty
                    if (checkbox.prop('checked') && !returnQuantityInput.val()) {
                        isValid = false;
                        returnQuantityInput.addClass('error');
                        return;
                    }
                    
                });
                return isValid;
        }


        // Enable or Disable the button based on Product line item checked.
        $(document).ready(function () {
            // Disable the button initially
            $('.returnclass').prop('disabled', true);
            $('input[name="dwfrm_orders_selectforReturn"]').on('change', function () {
                //Check if at least one checkbox is checked
                let atLeastOneChecked = $('input[name="dwfrm_orders_selectforReturn"]:checked').length > 0;
                

                //Handling the checkbox change event
                let $checkbox = $(this);
                let isChecked = $checkbox.prop('checked');
                let trLineItemSelected = $checkbox.closest('.productCheckbox').length > 0 ? $checkbox.closest('.productCheckbox')[0] : null; 
                let productId =  trLineItemSelected ? $(trLineItemSelected).data("plineitemid") : null;// To Get the product ID from the class attribute
                var returnQuantityInput = $(trLineItemSelected).find('.input_returntext');

                if(productId) {
                    //Show/Hide based on checkbox 
                    if (isChecked) {
                        $('.nextStep ' + (productId ? '.'+productId: 'na')).show();
                    } else {
                        returnQuantityInput.val('');
                        $('.nextStep ' + (productId ? '.'+productId: 'na')).hide();
                    }
                }
                
                $('.returnclass').prop('disabled', !validatereturnqty() || !atLeastOneChecked);
            });
            $('input[name="dwfrm_orders_quantity"]').on('change', function () {
                $('.returnclass').prop('disabled', !validatereturnqty());
            });
        });

        //If checkbox checked then create the object of LineItem details, return quantity, reason for return and action then create an array.
        function getSelectedProducts() {
                var selectedLineItems = [];
                
                //var sample = document.querySelector('.return_order_number');
                var ordrsummary = document.querySelector('.Order_item_summary');
                $('#tableqty tbody tr').each(function () {
                    var checkbox = $(this).find('input[name="dwfrm_orders_selectforReturn"]');
                    if (checkbox.prop('checked')) {
                    // For each selected checkbox of data.
                    var rowIndex = $(this).closest('tr').index();
                    var returnreason = $('#table2 tbody tr:eq(' + rowIndex + ') .dropdown').find(':selected').val();
                    var actiontaken = $('#table3 tbody tr:eq(' + rowIndex + ') .dropdown').find(':selected').val();
                    var row = $(this).closest('tr');
                    //var productId = row.find('.productid').text().trim();
                    //var productname = row.find('.returnproname').text().trim();
                    //var qty = parseInt(row.find('.qtyvalue').text().trim(), 10);
                    var returnqty = parseInt(row.find('.input_returntext').val(), 10);
                    var orderItemSummaryId = row.find('.itemSummaryId').text().trim();
                   // singleLineItemdata.OrderNumber = sample.innerText;

                    var singleLineItemdata = {
                        actiontaken : actiontaken,
                        orderItemSummaryId : orderItemSummaryId,
                        returnreason : returnreason,
                        returnqty : returnqty,
                   }
                   selectedLineItems.push(singleLineItemdata);
                }
                });
                return {ordersummaryid:ordrsummary.innerText, selectedLineItems: selectedLineItems};
        }


        function orderReturn() {
            const returnRequest= getSelectedProducts();
            var url = Urls.returnOrder;
            
            $('.return-submit-error').hide();
            progress.show($('.output_returnOrder'));
            $.ajax({
                type: 'POST',
                dataType: 'html', 
                contentType: 'application/json',
                url: url,
                data: JSON.stringify(returnRequest),
                success: function (data) {  
                    if(data){
                        $('.output_returnOrder').hide();
                        $('.returnorder-response').empty().html(data);
                    } else {
                        console.log("empty" , data);
                    }
                    progress.hide();
                },
                error: function(error){
                        $('.return-submit-error').show().css("display","block");
                        progress.hide();
                }
            });
        }
        
        //1.Create a Dialog Integration
        function ReturnOrderConfirmation() {
            // Get the confirmation message from the data attribute
            var confirmationMessage = $('.returnclass').attr('data-confirmationmessage');
            dialog.open({
                html: "<div class='return-dialog-confirm'>" + confirmationMessage + "</div>",
                options: {
                    modal: true,
                    height: "auto",
                    width: "auto",
                    open: function (event, _ui) {
                        $(event.target).scrollTop(0);
                    },
                    buttons: [{
                        text: "Yes",
                        showText: true,
                        click: function () {
                            $(this).dialog("close");
                            orderReturn();
                        }
                    },
                    {
                        text: "Cancel",
                        showText: true,
                        click: function () {
                            $(this).dialog("close");
                        }
                    }]
                }
            });
        }

        //2. If the return order condition is true the func renders to the ReturOrderConfirmation
        $('.returnclass').on('click', function (e) {
        e.preventDefault();
            var isValid = validatereturnqty();
            if (isValid) {
                ReturnOrderConfirmation();
            } else {
                console.log("Please Enter Correct Return Quantity");
            }
        });

/**
 * @function
 * @description Populates the billing address fields with the values used for shipping
 */
function initUseShippingAsBilling() {
	var $checkoutForm = $('.checkout-billing');
	$('input[name$="_useShippingAsBilling"]', $checkoutForm).on("change", function () {
		if ($(this).is(':checked')) {
            $('#billing_hide').hide();
            $('#mini_address_show .billingAddressCard').show();
			if ('shippingAddress' in User && !$.isEmptyObject(User.shippingAddress)) {
				$checkoutForm.find('input[name$="_firstName"]').val(User.shippingAddress.firstName);
				$checkoutForm.find('input[name$="_lastName"]').val(User.shippingAddress.lastName);
				$checkoutForm.find('input[name$="_address1"]').val(User.shippingAddress.address1);
				$checkoutForm.find('input[name$="_address2"]').val(User.shippingAddress.address2);
				$checkoutForm.find('input[name$="_city"]').val(User.shippingAddress.city);
				$checkoutForm.find('select[name$="_state"]').val(User.shippingAddress.state);
				$checkoutForm.find('input[name$="_postal"]').val(User.shippingAddress.postalCode);
				$checkoutForm.find('input[name$="_phone"]').val(User.shippingAddress.phone);
				$checkoutForm.find('select[name$="_billing_addressList"] option:first').prop('selected', true);
			}
            $(this).closest('div.form-row').addClass('visually-hidden');
		} else {
            $('#billing_hide').show();
            $('#mini_address_show .billingAddressCard').hide();           
			$checkoutForm.find('input[name$="_firstName"]').val('');
			$checkoutForm.find('input[name$="_lastName"]').val('');
			$checkoutForm.find('input[name$="_address1"]').val('');
			$checkoutForm.find('input[name$="_address2"]').val('');
			$checkoutForm.find('input[name$="_city"]').val('');
			$checkoutForm.find('select[name$="_state"] option:first').prop('selected',true);
			$checkoutForm.find('input[name$="_postal"]').val('');
			$checkoutForm.find('input[name$="_phone"]').val('');
		}
	});

    $('#btn_billing_edit').on('click', function () {
        var $_useShippingAsBilling = $('input[name$="_useShippingAsBilling"]');
        $_useShippingAsBilling.prop("checked", !$_useShippingAsBilling.prop("checked"));
        if (!$_useShippingAsBilling.prop("checked")) {
            $_useShippingAsBilling.closest('div.form-row').removeClass('visually-hidden');
        } else {
            $_useShippingAsBilling.closest('div.form-row').addClass('visually-hidden');
        }
        $('input[name$="_useShippingAsBilling"]', $checkoutForm).trigger("change");
    });
	
	// If the user makes changes to the fields, we should uncheck the 'Use shipping as billing' box
	$('input', $checkoutForm).filter('[name$="_firstName"],[name$="_lastName"],[name$="_address1"],[name$="_address2"],[name$="_city"],[name$="_postal"],[name$="_phone"]').keyup(function () {
		$('input[name$="_useShippingAsBilling"]', $checkoutForm).prop('checked', false);
	});

	// The state drop-down doesn't have a keyup event, so we listen for it to be changed.
	$('select[name$="_state"]', $checkoutForm).on('change', function () {
		$('input[name$="_useShippingAsBilling"]', $checkoutForm).prop('checked', false);
	});
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
    var $checkoutForm = $('.checkout-billing');
    var $addGiftCert = $('#add-giftcert');
    var $giftCertCode = $('input[name$="_giftCertCode"]');
    var $addCoupon = $('#add-coupon');
    var $couponCode = $('input[name$="_couponCode"]');
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();

    // This will reset the payment details on card type change to prevent functional issue
    $("#dwfrm_billing_paymentMethods_creditCard_type").on('change',function() {
	    	$("input[id^='dwfrm_billing_paymentMethods_creditCard_number']").val('');
	    	$("#dwfrm_billing_paymentMethods_creditCard_expiration_month option:first").prop('selected',true);
	    	$("#dwfrm_billing_paymentMethods_creditCard_expiration_year option:first").prop('selected',true);
	    	$("input[id^='dwfrm_billing_paymentMethods_creditCard_cvn']").val('');
	    	$('.checkout-billing').find('input[name$="_selectedCardID"]').val('');
	    	$('#creditCardList option:first').prop('selected',true).trigger('change');
    });
    
    formPrepare.init({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });

    // default payment method to 'CREDIT_CARD'
    updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
    $selectPaymentMethod.on('click', 'input[type="radio"]', function () {
        updatePaymentMethod($(this).val());
    });
    
    //this is make the whole expiration field container tag as error in billing page
    if($('.expiration-container .form-row.month').hasClass('error')) {
    		$('.expiration-container').addClass('error');
    }


    // select credit card from list
    var $creditCardList = $('#creditCardList');
    $creditCardList.on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) {
        		$($checkoutForm).find('input[name$="_selectedCardID"]').val(''); 
        		return;
        	}
        populateCreditCardForm(cardUUID);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });
    
    //preselect default credit card
    if($('#paymentError').val() === 'false') {
	    if($creditCardList.length > 0){
			$creditCardList.find('option').eq(1).prop('selected', true);
			$creditCardList.trigger('change');
		}
    }

    $('#check-giftcert').on('click', function (e) {
        e.preventDefault();
        var $balance = $('.balance');
        if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
            var error = $balance.find('span.error');
            if (error.length === 0) {
                error = $('<span>').addClass('error').appendTo($balance);
            }
            error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        giftcard.checkBalance($giftCertCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass('success').addClass('error');
                return;
            }
            $balance.html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance).removeClass('error').addClass('success');
        });
    });

    $addGiftCert.on('click', function (e) {
        e.preventDefault();
        var code = $giftCertCode.val(),
            $error = $checkoutForm.find('.giftcert-error');
        if (code.length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {giftCertCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                return;
            } else {
                window.location.assign(Urls.billing);
            }
        });
    });

    $addCoupon.on('click', function (e) {
        e.preventDefault();
        var $error = $checkoutForm.find('.coupon-error'),
        		$sucess = $checkoutForm.find('.coupon-success'),
            code = $couponCode.val();
        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);
            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {couponCode: code, format: 'ajax'});
        $.getJSON(url, function (data) {
            var fail = false;
            var msg = '';
            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
                fail = true;
            }
            if (fail) {
                $error.html(msg);
                $sucess.html('');
                return;
            }
            
            if(data.success) {
            		$sucess.html(data.message);
            		window.location.assign(Urls.billing);
            		return;
            }
            
            //basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            //this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing);
            }
        });
    });

    // trigger events on enter
    $couponCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addCoupon.click();
        }
    });
    $giftCertCode.on('keydown', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $addGiftCert.click();
        }
    });
    
    initUseShippingAsBilling();
};
